<template lang="pug">
	article#ingressosEvento(v-if="lotes && loteAtivo != null")
		figure.banner_evento
			img(:src="require('@images/backgrounds/bgLocal.jpg')")
		section#titulo
			.wrapper
				router-link(:to="{name: 'evento'}").voltar
					img(:src="require('@images/icones/arrow.png')")
					span Voltar para o evento
				h2 Inscrições
		Categorias(:lote='lotes[loteAtivo]', @openIngressos="openIngressos")
		Ingressos(:ingresso="lotes[loteAtivo].ingressos[categoriaAtiva]", :associacoes="associacoes")
		Lotes(:lotes="lotes", :loteAtivo="loteAtivo" :categoriaAtiva="categoriaAtiva")
		Cursos(:cursos="lotes[loteAtivo].cursos", v-if="lotes[loteAtivo].cursos.length")
		Condicoes(:condicoes="condicoes", :descricao="descricaoCondicao")

</template>

<script>
import Cursos from '@vue/sections/ingressos/cursos/Cursos.vue';
import Ingressos from '@vue/sections/ingressos/ingressos/Ingressos.vue';
import Lotes from '@vue/sections/ingressos/lotes/Lotes.vue';
import Condicoes from '@vue/sections/ingressos/condicoes/Condicoes.vue';
import Categorias from '@vue/sections/ingressos/categorias/Categorias.vue';

export default {
	components: {
		Cursos,
		Condicoes,
		Lotes,
		Categorias,
		Ingressos,
	},
	data() {
		return {
			associacoes: null,
			lotes: null,
			cursos: null,
			condicoes: null,
			descricaoCondicao: null,
			loteAtivo: 0,
			categoriaAtiva: 0
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.$axios
				.get(`api/eventos/${to.params.slug}/ingressos`)
				.then(response => {
					vm.associacoes = response.data.associacoes
					vm.lotes = response.data.lotes
					vm.cursos = response.data.cursos
					vm.condicoes = response.data.condicoes
					vm.descricaoCondicao = response.data.condicao_descritivo
					vm.loteAtivo = response.data.lote_ativo
					if (vm.loteAtivo === null)
						vm.$router.replace({
							name: 'evento',
							params: { slug: to.params.slug }
						})
				})
				.catch(() => {
					vm.$router.replace({ name: '404' })
				})
		})
	},
	methods: {
		openIngressos(event) {
			this.categoriaAtiva = event.index
		}
	}
}
</script>

<style lang="stylus" scoped src="./Ingressos.styl"></style>