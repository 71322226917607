<template lang="pug">
    section#lotes
        .wrapper
            ul
                li(v-for="lote, index in lotes")
                    p(:class="{'esgotado' : index < loteAtivo, 'ativo' : index == loteAtivo}") R${{lote.ingressos[categoriaAtiva].associado.valor}}
                    span até {{lote.fim | formatData}}

</template>

<script>
export default {
    props: {
        lotes: Array,
        loteAtivo: Number,
        categoriaAtiva: Number,
    },
    filters: {
        formatData(value) {
            if(!value)
                return ''
            var format = value.slice(0, value.length - 5)
            return format
        }
    }
}
</script>

<style lang="stylus" scoped src="./Lotes.styl">
</style>