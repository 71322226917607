<template lang="pug">
    section#condicoes
        .wrapper
            h3 Condições Gerais
            div(v-html="descricao")
            ul
                li(v-for="condicao in condicoes")
                    h4 {{condicao.titulo}}
                    p {{condicao.texto}}
            //-h4(@click="toggleAside('devolucao')").devolucao Política de Devolução
        //-asideDevolucao(:class="{'ativo': aside.devolucao }" :descricao="$configuracoes.configuracoes.politica_devolucao")
</template>

<script>
import asideDevolucao from '@sections/ingressos/asideDevolucao/AsideDevolucao.vue'

export default {
	components: {
        asideDevolucao
	},
    props: {
        condicoes: Array,
        descricao: String
    },
    data() {
        return {
            aside: {
                devolucao: false
            }
        }
    },
    methods: {
        toggleAside(aside) {
			this.aside[aside] = !this.aside[aside]
			if(this.aside[aside])
				document.querySelector('html').style.overflow = 'hidden'
			else
				document.querySelector('html').style.overflow = 'visible'
		}
    },
}
</script>

<style lang="stylus" scoped src="./Condicoes.styl">
</style>
