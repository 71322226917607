<template lang="pug">
	aside#devolucao
		div.container
			button(@click="$parent.toggleAside('devolucao')") X
			h3 Política de Devolução
			div.conteudo(v-html="descricao")
</template>

<script>
export default {
	components: {
	},
	props:{
		descricao: String,
	},
}
</script>

<style lang="stylus" scoped src="./AsideDevolucao.styl"></style>