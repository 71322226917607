<template lang="pug">
    section#ingressos
        .wrapper
            div
                ul(v-if="!ingresso.extra")
                    li
                        .conteudo
                            h4 Inscrição {{ingresso.categoria}} Associado ANCP
                            p.preco 
                                |R$
                                span {{ingresso.associado.valor | fixed}}
                            button(@click="comprarIngresso(ingresso.associado.produto_id)", :disabled="$carrinho.inProdutos(ingresso.associado.produto_id) && !$carrinho.inProdutos(associacaoPorCategoria.produto_id)").comprar 
                                | {{ $carrinho.inProdutos(ingresso.associado.produto_id) && !$carrinho.inProdutos(associacaoPorCategoria.produto_id) ? 'Adicionado' : 'Comprar' }}
                            // p.parcelas 10x de R$ {{ingresso.associado.valor|transformFloat}} no cartão de crédito
                    li
                        .conteudo
                            h4 Inscrição {{ingresso.categoria}} + Associação ANCP
                            p.preco 
                                |R$
                                span {{ valorCombo | fixed }}
                            button(@click="comprarIngresso(ingresso.associado.produto_id, true)", :disabled="$carrinho.inProdutos(ingresso.associado.produto_id) && $carrinho.inProdutos(associacaoPorCategoria.produto_id)").comprar
                                | {{ $carrinho.inProdutos(ingresso.associado.produto_id) && $carrinho.inProdutos(associacaoPorCategoria.produto_id) ? 'Adicionado' : 'Comprar' }}
                            // p.parcelas 10x de R$ {{ valorCombo | transformFloat }} no cartão de crédito
                    li
                        .conteudo
                            h4 Inscrição {{ingresso.categoria}} Não Associado
                            p.preco 
                                |R$
                                span {{ingresso.normal.valor | fixed}}
                            button(@click="comprarIngresso(ingresso.normal.produto_id)", :disabled="$carrinho.inProdutos(ingresso.normal.produto_id)").comprar
                                | {{ $carrinho.inProdutos(ingresso.normal.produto_id) ? 'Adicionado' : 'Comprar' }}
                            // p.parcelas 10x de R$ {{ingresso.normal.valor|transformFloat}} no cartão de crédito
                ul(v-else)
                    li
                        .conteudo
                            h4 Inscrição {{ingresso.categoria}} Não Associado
                            p.preco 
                                |R$
                                span {{ingresso.valor}}
                            button.comprar Comprar
                            // p.parcelas 10x de R$ {{ingresso.valor|transformFloat}} no cartão de crédito
                         
</template>

<script>
export default {
    props: {
        ingresso: Object,
        associacoes: Array
    },
    filters: {
        transformFloat(value) {
            let numero = parseFloat(value / 10).toFixed(2);
            return new Number(numero).toLocaleString("pt-BR");
        },
        fixed(value) {
            let numero = parseFloat(value).toFixed(2);
            numero = new Number(numero).toLocaleString("pt-BR");
            if (numero.search(',') == -1)
                numero = numero + ",00"
            return numero;
        }
    },
    computed: {
        associacaoPorCategoria() {
            let associacaoPorCategoria = this.$props.associacoes.find(item => item.categoria_id == this.ingresso.categoria_id)
            return associacaoPorCategoria ? associacaoPorCategoria : { valor: 0 }
        },
        valorCombo() {
            return parseFloat(this.ingresso.associado.valor) + parseFloat(this.associacaoPorCategoria.valor);
        }
    },
    methods: {
        comprarIngresso(produto_id, isCombo = false) {
            if (isCombo)
                this.$carrinho.adicionar([produto_id, this.associacaoPorCategoria.produto_id]);
            else
                this.$carrinho.adicionar(produto_id);
        }
    },
}
</script>

<style lang="stylus" scoped src="./Ingressos.styl">
</style>