<template lang="pug">
    section#categorias
        .wrapper
            ul
                li(v-for="ingresso, index in lote.ingressos" @click="$emit('openIngressos', {categoria: ingresso.categoria, index})")
                    .conteudo(v-if="!ingresso.extra")
                        h4 {{ingresso.categoria}}
                        p.preco 
                            |R$
                            span {{ingresso.associado.valor | fixed}}
                        p.associados para associados ANCP
                        p.naoAssociados R${{ingresso.normal.valor}} para não associados ANCP
                    .conteudo(v-else)
                        h4 {{ingresso.categoria}}
                        p.preco 
                            |R$
                            span {{ingresso.valor}}
                        
</template>

<script>
export default {
    props: {
        lote: Object,
    },
    filters: {
        fixed(value) {
            let numero = parseFloat(value).toFixed(2);
            numero = new Number(numero).toLocaleString("pt-BR");
            if (numero.search(',') == -1)
                numero = numero + ",00"
            return numero;
        }
    }
}
</script>

<style lang="stylus" scoped src="./Categorias.styl">
</style>