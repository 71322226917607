<template lang="pug">
	section#cursos
		.wrapper
			h3 Cursos pré-congresso
			ul
				li(v-for="curso,index in cursos")
					.conteudo
						h4 {{curso.nome}}
						p {{curso.curso.hora_inicio}} - {{curso.curso.hora_fim}} - {{curso.curso.local}}
						p R$ {{curso.valor}}
						button.saiba(@click="abrirAside(index)") Saiba mais
					button(@click="$carrinho.adicionar(curso.produto_id)", :disabled="$carrinho.inProdutos(curso.produto_id)", v-if="!curso.curso.esgotado").adquira 
						| {{ $carrinho.inProdutos(curso.produto_id) ? 'Adicionado' : 'Adquira' }}
					button(disabled, v-else).adquira 
						| Esgotado
		aside#asidecursos(:class="{'ativo' : aside}", v-if="cursos")
			div.container
				button(@click="abrirAside()") X
				h3 {{ cursos[cursoAtivo].nome }}
				div.conteudo(v-html="cursos[cursoAtivo].curso.descricao")
						
</template>

<script>
export default {
	components: {
	},
	props: {
		cursos: Array,
	},
	data() {
		return {
			aside: false,
			cursoAtivo: 0
		}
	},
	methods: {
		abrirAside(index = null){
			this.aside = !this.aside
			if(index != null)
				this.cursoAtivo = index
			if(this.aside)
				document.querySelector('html').style.overflow = 'hidden'
			else
				document.querySelector('html').style.overflow = 'visible'
		}
	},
}
</script>

<style lang="stylus" scoped src="./Cursos.styl"></style>